<template>
  <module
    ref="module"
    id="explanation"
    title-icon="fa fa-usd"
    :title="$t('pricingAndPayoutExplanations')"
    :use-default-list="false"
  >
    <div slot="global">
      <div class="col-md-10 mx-auto">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title text-center">{{ $t('pricingAndPayoutExplanations') }}</h3>
          </div>
          <div class="card-body">
            <div v-if="inProgress" class="text-center">
              <h2>{{ $t('loadingtxt') }}</h2>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>
            <div v-else>
              <div class="col-md-12">
                <h6>
                  {{ $t('explanations_text_01') }}
                </h6>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4 mt-4 mb-4">
                    <div class="text-center">
                      <h6><b>{{ $t('vps') }}</b></h6>
                    </div>
                    <table style="width: 100%;">
                      <tr>
                        <th class="text-left">{{ $t('vpsplans') }}</th>
                        <th class="text-right">{{ $t('dailycost') }}</th>
                      </tr>
                      <tr v-for="item in vps_pricing">
                        <td class="text-left">{{ item.name }}</td>
                        <td class="text-right">$ {{ item.daily_cost }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4 mt-4 mb-4">
                    <div class="text-center">
                      <h6><b>{{ $t('copytradingproduct') }}</b></h6>
                    </div>
                    <table style="width: 100%;">
                      <tr>
                        <th class="text-left">{{ $t('accountType') }}<small class="text-muted">(MT4/MT5)</small></th>
                        <th class="text-right">{{ $t('dailycost') }}</th>
                      </tr>
                      <tr>
                        <td class="text-left">{{ $t('liveAccounts') }}</td>
                        <td class="text-right"> $ {{ strategy_pricing.live_account }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">{{ $t('demoAccounts') }}</td>
                        <td class="text-right"> $ {{ strategy_pricing.demo_account }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">{{ $t('sponsorAccounts') }}</td>
                        <td class="text-right"> $ {{ strategy_pricing.sponsored_account }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-4 mt-4 mb-4">
                    <div class="text-center">
                      <h6><b>{{ $t('pricing_public') }}</b></h6>
                    </div>
                    <p>{{ $t('explanations_public_strategies') }}</p>
                  </div>
                </div>
              </div>
              <br>
            </div>

          </div>
        </div>

      </div>
    </div>

  </module>
</template>

<script>
export default {
  name: "PayoutExplanation",
  data() {
    return {
      vps_pricing: [],
      strategy_pricing: {},
      inProgress: true,
    }
  },
  methods: {},
  mounted() {
    let success = resp => {
      this.strategy_pricing = resp.data.strategy_pricing
      this.vps_pricing = resp.data.vps_pricing
      this.inProgress = false
    }
    let fail = resp => {
      console.log(resp)
    }
    this.$getPricingInfo().then(success, fail)
  }
}
</script>

<style scoped>

</style>
